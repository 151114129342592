.image-viewer, .zoomable-image-viewer {
    background-color: #F0F0F0;
}

.zoomable-image-viewer {
    .zoom-slider {
        top: 5px;
        left: 5px;
        padding: 7px;
        background-color: rgba(197, 197, 196, 0.8);
    }
}