.user-form {
    .alert {
        border-radius: 0;
        padding: 8px 35px 8px 14px;
    }

    .help-block.error {
        color: #b94a48;
    }

    ul.form-help-list {
        margin-bottom: 0;

        .list-unstyled();

        li {
            font-size: .8em;
            text-decoration: underline;
            margin-bottom: 5px;
        }
    }
}

.studio-form {
    .flex-preview-inline {
        display: flex;

        .preview {
            flex-grow: 1;
            margin-right: 15px;
        }

        .controls {
            flex-basis: 100%;

            & > div {
                margin-top: 10px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .form-group {
        margin-bottom: 10px;
    }

    .form-control:focus {
        border-color: #c5c5c4;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(77, 77, 77, .2);
    }

    fieldset {
        margin-bottom: 5px;

        & > legend {
            font-size: 18px;
            font-weight: bold;
            color: #c5c5c4;
            margin-bottom: 10px;
        }

        .control-label {
            color: #c5c5c4;
        }
    }
}