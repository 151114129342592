.panel-default,
.panel-group .panel {
  border-radius: 0;
  border-color: #c5c5c4;

  .panel-heading {
    color: #fff;
    border-radius: 0;
    background-image: none;
    background-color: #c5c5c4;
    border-color: #c5c5c4;
    font-weight: bold;
  }

  .panel-footer {
    background-image: none;
    background-color: #eee;
  }

  .panel-title {
    border-top: 1px solid #c5c5c4;
    font-weight: bold;
  }
}

.panel-group {
  .info-panel {
    .panel-title a {
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }
}

.info-panel {
  max-width: 560px;
  margin: 0 auto;

  .panel-body {
    max-height: 400px;
    overflow-y: auto;

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.info-panel-onetrust {
  max-width: 100%;
  margin: 0 auto;

  .panel-body {
    // max-height: 400px;
    overflow-y: auto;

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}
