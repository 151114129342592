.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    top: 40px;
    width: 280px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 10;

    // Anchor suggest list to right edge of input group.
    right: 0;
}

.react-autosuggest__suggestions-list {
    .list-unstyled();
    margin-bottom: 0;

    li {
        border-top: 1px solid #ccc;
        &:first-child {
            border-top: 0;
        }
    }
}