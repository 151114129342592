.pagination {
    border-radius: 0;

    & > li {
        display: inline-block;
    }

    & > li > a,
    & > li > span {
        display: inline-block;
        
        // Note: this indicates that the property is ignored,
        // but it does appear to be necessary since we are overriding
        // the fact that it is setup to float: left; by bootstrap defaults.
        float: none;
    }

    & > li:first-child > a,
    & > li:first-child > span {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    & > li:last-child > a,
    & > li:last-child > span {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    & > .active > a,
    & > .active > span,
    & > .active > a:hover,
    & > .active > span:hover,
    & > .active > a:focus,
    & > .active > span:focus {
        background-color: #c5c5c4;
        border-color: #c5c5c4;
    }

    & > .disabled > span,
    & > .disabled > span:hover,
    & > .disabled > span:focus,
    & > .disabled > a,
    & > .disabled > a:hover,
    & > .disabled > a:focus {
        color: #606060;
        border-color: #c5c5c4;
    }

    & > li > a,
    & > li > span {
        font-weight: normal;
        color: #606060;
        border-color: #c5c5c4;
    }

    & > li > a:hover,
    & > li > span:hover,
    & > li > a:focus,
    & > li > span:focus {
        color: #606060;
        background-color: #eee;
    }
}