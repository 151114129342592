@import (reference) "~Style/mixins";

html, body {
    height: 100%;
}

#app {
    height: 100%;
	
	& > div {
		height: 100%;
	}

    @import "./search-input.less";
}

@import "./alerts.less";
@import "./anchors.less";
@import "./breadcrumbs.less";
@import "./buttons.less";
@import "./dropdowns.less";
@import "./forms.less";
@import "./image-viewer.less";
@import "./modals.less";
@import "./navs.less";
@import "./pagers.less";
@import "./pagination.less";
@import "./panels.less";
@import "./react-autosuggest.less";
@import "./selectable-text.less";
@import "./swatch-frame-editor.less";
@import "./wells.less";