.search-input {
  margin-bottom: 5px;

  .input-group {
    input:focus {
      outline: none;
      border: 1px solid #ccc;
      -webkit-box-shadow: inherit;
      box-shadow: inherit;
    }

    .input-group-addon {
      color: #fff;
      background-color: #231f20;
      border: 1px solid #231f20;
    }

    .form-control {
      border: 1px solid #231f20;

      box-shadow: none;
      -webkit-box-shadow: none;
    }

    .form-control,
    .input-group-addon {
      border-radius: 0;
    }
  }
}
