.modal-dialog {
    .modal-content {
        border-radius: 0;
    }
}

.studio-dialog {
    .modal-header {
        background-image: none;
        background-color: #c5c5c4;
        padding: 10px 15px;
        border-width: 0;

        button.close {
            padding-top: 3px;
            color: #777;
            opacity: 1;
            text-shadow: 0 0 0 #fff;
        }

        .modal-title {
            color: #fff;
            font-weight: bold;

            small {
                color: #fff;
                margin-left: 5px;
                font-size: 85%;
            }
        }
    }

    .modal-footer {
        padding: 10px 15px;
    }
}