// Dropdown submenu support was dropped in Bootstrap 3.
// See more: http://stackoverflow.com/a/18024991

.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
    margin-left:-1px;
    -webkit-border-radius:0 6px 6px 6px;
    -moz-border-radius:0 6px 6px 6px;
    border-radius:0 6px 6px 6px;
}
.dropdown-submenu:hover>.dropdown-menu {
    display:block;
}
.dropdown-submenu>a:after {
    display:block;
    content:" ";
    float:right;
    width:0;
    height:0;
    border-color:transparent;
    border-style:solid;
    border-width:5px 0 5px 5px;
    border-left-color:#cccccc;
    margin-top:5px;
    margin-right:-10px;
}
.dropdown-submenu:hover>a:after {
    border-left-color:#fff;
}
.dropdown-submenu.pull-left {
    float:none;
}
.dropdown-submenu.pull-left>.dropdown-menu {
    left:-100%;
    margin-left:10px;
    -webkit-border-radius:6px 0 6px 6px;
    -moz-border-radius:6px 0 6px 6px;
    border-radius:6px 0 6px 6px;
}

.dropdown-submenu:hover > a:after {
	border-left-color: #606060;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
	color: #606060;
	text-decoration: none;
	background-color: #f5f5f5;
	background-image: -moz-linear-gradient(top, #f5f5f5, #f5f5f5);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f5f5f5));
	background-image: -webkit-linear-gradient(top, #f5f5f5, #f5f5f5);
	background-image: -o-linear-gradient(top, #f5f5f5, #f5f5f5);
	background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5);
	background-repeat: repeat-x;
	filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",rgba(245, 245, 245, 1),rgba(245, 245, 245, 1)));

    // Note: Couldn't find reference to this background image.
	/*[class^="icon-"],
	[class*=" icon-"] {
	    background-image: url("/Content/images/glyphicons-halflings.png");
	}*/
}

.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
	color: #606060;
	text-decoration: none;
	background-color: #f5f5f5;
	background-image: -moz-linear-gradient(top, #f5f5f5, #f5f5f5);
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f5f5f5));
	background-image: -webkit-linear-gradient(top, #f5f5f5, #f5f5f5);
	background-image: -o-linear-gradient(top, #f5f5f5, #f5f5f5);
	background-image: linear-gradient(to bottom, #f5f5f5, #f5f5f5);
	background-repeat: repeat-x;
	outline: 0;
	filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",rgba(245, 245, 245, 1),rgba(245, 245, 245, 1)));

	// Note: Couldn't find reference to this background image.
	/*[class^="icon-"],
	[class*=" icon-"] {
	    background-image: url("/Content/images/glyphicons-halflings.png");
	}*/
}